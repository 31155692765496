// CallOptionDetails.js

import React from 'react';
import { useState, useEffect, createContext, useContext } from "react";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import Box from '@material-ui/core/Box';
import { Alert, AlertTitle } from '@material-ui/lab';

import Tooltip from '@material-ui/core/Tooltip';
import { InputLabel } from '@material-ui/core';
import Chip from '@mui/material/Chip';

import { LongUnderlyingProductValue, Number2Dollar, FormattedNumber, CheckAuthenticated, ButtonAppBar, StartEnd, StartEnd2, Rounding, date2string, OTM_ATM_ITM } from './Common.js';
import Typography from '@material-ui/core/Typography';
import Button from '@mui/material/Button';

export default function CallOptionDetails(props){

    const { 
      // OptionTableListItem, 
      CallOption,
      CallProductCodeSubmitAction, 
      OptionList 
    } = props;
  
    // let _call_bid = LongUnderlyingProductValue("IndexOption", OptionTableListItem.CallBid);
    // let _call_ask = LongUnderlyingProductValue("IndexOption", OptionTableListItem.CallAsk);
    // let _call_last = LongUnderlyingProductValue("IndexOption", OptionTableListItem.CallLast);
  
    // let _call_close = LongUnderlyingProductValue("IndexOption", OptionTableListItem.CallClose);
    // let _call_open = LongUnderlyingProductValue("IndexOption", OptionTableListItem.CallOpen);
  
    // let _call_volume = LongUnderlyingProductValue("IndexOption", OptionTableListItem.CallVolume);
    // let _call_open_interest = LongUnderlyingProductValue("IndexOption", OptionTableListItem.CallOpenInterest);
    // let _call_previous_open_interest = LongUnderlyingProductValue("IndexOption", OptionTableListItem.CallPreviousOpenInterest);
  
    // let _call_delta = Rounding(OptionTableListItem.CallDelta, 2);
    // let _call_gamma = Rounding(OptionTableListItem.CallGamma, 2);
    // let _call_theta = Rounding(OptionTableListItem.CallTheta, 2);
    // let _call_vega = Rounding(OptionTableListItem.CallVega, 2);
    // let _call_implied_volatility = Rounding(OptionTableListItem.CallImpliedVolatility, 2);
  
    return(
  
      <div>
  
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
              <br/>
              <Typography>
              認購期權 ({OTM_ATM_ITM(OptionList, CallOption.ProductCode)})
              </Typography>
              </Grid>
            </Grid>
  
        <br/>
        {StartEnd2('賣出', CallOption.Bid + ' ' + date2string(CallOption.RevisionDateTimeHKBid, true, false, false), 3, 9)}
        {StartEnd2('買入', CallOption.Ask + ' ' + date2string(CallOption.RevisionDateTimeHKAsk, true, false, false), 3, 9)}
        {StartEnd2('最後', CallOption.Last + ' ' + date2string(CallOption.RevisionDateTimeHKLast, true, false, false), 3, 9)}
        <br/>
  
        <Grid container spacing={1}>
          <Grid item xs={6} lg={6}>
            {StartEnd2('前收市', CallOption.Close, 7, 5)}
          </Grid>
          <Grid item xs={6} lg={6}>
            {StartEnd2('開市', CallOption.Open, 7, 5)}
          </Grid>
          <Grid item xs={12} lg={12}>
            {StartEnd2('成交量', CallOption.Volume + ' ' + date2string(CallOption.RevisionDateTimeHKVolume, true, false, false), 4, 8)}
          </Grid>
          <br/><br/>
          <Grid item xs={12} lg={12}>
            {StartEnd2('未平倉', CallOption.OpenInterest + ' ' + date2string(CallOption.RevisionDateTimeHKOpenInterest, true, false, false), 4, 8)}
          </Grid>  
          <Grid item xs={12} lg={12}>
            {StartEnd2('前未平倉', CallOption.PreviousOpenInterest + ' ' + date2string(CallOption.RevisionDateTimeHKPreviousOpenInterest, true, false, false), 4, 8)}
          </Grid>        
        </Grid> 
  
        <br/>
        {date2string(CallOption.RevisionDateTimeHKOptionComputation, false, false, false)}
        <br/>
        {StartEnd2('Delta', CallOption.Delta, 3, 9)}
        {StartEnd2('Theta', CallOption.Theta, 3, 9)}
        {StartEnd2('Vega', CallOption.Vega, 3, 9)}
        {StartEnd2('引伸波幅', CallOption.ImpliedVolatility, 8, 4)}
        <br/>
        <Chip label={CallOption.ProductCode} color="success" onClick={()=>{CallProductCodeSubmitAction(CallOption)}}/>
        <br/><br/>
      </div>
  
    )
  
}