// OptionPlanner.js

import { BrowserRouter, Route } from "react-router-dom";
import React from 'react';
import { useState, useEffect, createContext, useContext } from "react";
import ReactDOM from 'react-dom';
import axios from 'axios';
import { useRoutes, A, navigate } from "hookrouter";
import MaterialTable from "material-table";

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { sizing } from '@material-ui/system';
import Container from '@material-ui/core/Container';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FolderIcon from '@material-ui/icons/Folder';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { DataGrid } from '@material-ui/data-grid';
import { Button, InputLabel } from '@material-ui/core';

import AuthenticationService from '../AuthenticationService.js';
import Options4Display from "./Options4Display.js";
import Options4Submit from "./Options4Submit.js";
import CurrentHistorical from './CurrentHistorical.js';
import MaterialTableOptionPlanner from "./MaterialTableOptionPlanner.js";
import ButtonAppBar from "./ButtonAppBar.js";
import { LongUnderlyingProductValue, Number2Dollar, FormattedNumber, CheckAuthenticated } from './Common.js';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(0),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function OptionPlanner(){

  const [RefreshDate, setRefreshDate] = useState('');
  const [RefreshHour, setRefreshHour] = useState('');
  const [RefreshMinute, setRefreshMinute] = useState('');

  const [ProductType, setProductType] = useState('IndexOption');
  const [UnderlyingProductCode, setUnderlyingProductCode] = useState('HK.HSI');
  const [UnderlyingProductValue, setUnderlyingProductValue] = useState(0.0);
  const [Expiry, setExpiry] = useState('');
  const [StrikeInterval, setStrikeInterval] = useState(0);
  const [TimeStatus, setTimeStatus] = useState('Current');
  
  const [BaseContract, setBaseContract] = useState(1);
  const [RequireUnderlying, setRequireUnderlying] = useState(false);
  const [OutlookUnderlying, setOutlookUnderlying] = useState('Bearish');
  const [ProfitPotential, setProfitPotential] = useState('Limited');
  const [LossPotential, setLossPotential] = useState('Limited');
  const [CreditDebit, setCreditDebit] = useState('Credit');
  const [Leg, setLeg] = useState(1);
  const [Left, setLeft] = useState(false);
  const [Center, setCenter] = useState(true);
  const [Right, setRight] = useState(false);
  
  const [SubmitButtonColor, setSubmitButtonColor] = useState('primary');
  const [SubmitButtonLabel, setSubmitButtonLabel] = useState('確定');
  const [ListOptionDetailsList, setListOptionDetailsList] = useState([]);
  const [RefreshDateTime, setRefreshDateTime] = useState('');
  const [CombinationList, setCombinationList] = useState([]);
  const [CombinationListLength, setCombinationListLength] = useState(0);
  const [ProductType4UnderlyingProductValue, setProductType4UnderlyingProductValue] = useState('');

  const [Left_0, setLeft_0] = useState('');
  const [Center_0, setCenter_0] = useState('');
  const [Right_0, setRight_0] = useState('');
  const [ProductType_0, setProductType_0] = useState('');
  const [ProductType4UnderlyingProductValue_0, setProductType4UnderlyingProductValue_0] = useState('');
  const [UnderlyingProductCode_0, setUnderlyingProductCode_0] = useState('');
  const [UnderlyingProductValue_0, setUnderlyingProductValue_0] = useState('');
  const [StrikeInterval_0, setStrikeInterval_0] = useState('');
  const [Expiry_0, setExpiry_0] = useState('');
  const [BaseContract_0, setBaseContract_0] = useState(1);
  const [ListOptionDetailsList_0, setListOptionDetailsList_0] = useState([]);
  const [RefreshDateTime_0, setRefreshDateTime_0] = useState('');
  const [CombinationList_0, setCombinationList_0] = useState([]);
  const [CombinationListLength_0, setCombinationListLength_0] = useState(0);

  function CombinationList_Get(){

    var V_OutlookUnderlying, V_ProfitPotential, V_LossPotential, V_CreditDebit, V_Leg, V_RequireUnderlying;

    RequireUnderlying == '' ? V_RequireUnderlying = '0' : V_RequireUnderlying = RequireUnderlying;

    OutlookUnderlying == '' ? V_OutlookUnderlying = '0' : V_OutlookUnderlying = OutlookUnderlying;
    ProfitPotential == '' ? V_ProfitPotential = '0' : V_ProfitPotential = ProfitPotential;
    LossPotential == '' ? V_LossPotential = '0' : V_LossPotential = LossPotential;
    CreditDebit == '' ? V_CreditDebit = '0' : V_CreditDebit = CreditDebit;
    Leg == '' ? V_Leg = '0' : V_Leg = Leg;    

    var v_http_post_data = {
      Credential: AuthenticationService.getCurrentUser(),
      OutlookUnderlying: V_OutlookUnderlying,
      ProfitPotential: V_ProfitPotential,
      LossPotential: V_LossPotential,
      CreditDebit: V_CreditDebit,
      Leg: V_Leg,
      RequireUnderlying: V_RequireUnderlying,
    }

    const url = sessionStorage.getItem('SiteURL') + 'api/CombinationList/';

    console.log('OutlookUnderlying: ' + V_OutlookUnderlying + ', ProfitPotential: ' + V_ProfitPotential + ', LossPotential: ' + V_LossPotential + ', CreditDebit: ' + V_CreditDebit + ', Leg: ' + V_Leg + ', RequireUnderlying: ' + V_RequireUnderlying);

    axios.post(url, v_http_post_data)

      .then(resp => {

      let list = resp.data;

          console.log('Size of CombinationList: ' + list.length);

          let RefreshDateTime = '';

            switch(TimeStatus){
                case 'Historical':
                  RefreshDateTime = RefreshDate + RefreshHour + RefreshMinute;
                  break;
                case 'Current':
                  RefreshDateTime = '';
                  break;
            }

            setCombinationList(list);
            setCombinationList_0(list);

            setCombinationListLength(list.length);
            setCombinationListLength_0(list.length);

            setRefreshDateTime(RefreshDateTime);
            setRefreshDateTime_0(RefreshDateTime);

            setProductType4UnderlyingProductValue(ProductType);
            setProductType4UnderlyingProductValue_0(ProductType);

            setLeft_0(Left);
            setCenter_0(Center);
            setRight_0(Right);

            setProductType_0(ProductType);            
            
            setUnderlyingProductCode_0(UnderlyingProductCode);
            setUnderlyingProductValue_0(UnderlyingProductValue);
            setStrikeInterval_0(StrikeInterval);
            setExpiry_0(Expiry);
            setBaseContract_0(BaseContract);
            setListOptionDetailsList_0(ListOptionDetailsList);

            setSubmitButtonLabel('更新現貨價');
            setSubmitButtonColor('secondary');

      });
        
  }

  function ListOptionDetailsList_Get(){

    var v_http_post_data = {
      Credential: AuthenticationService.getCurrentUser(),
      ProductType: ProductType,//
      UnderlyingProductCode: UnderlyingProductCode,//
      Expiry: Expiry,//
      BaseContract: BaseContract,//
      TimeStatus: TimeStatus,//
    }

    const url = sessionStorage.getItem('SiteURL') + 'api/OptionDetailsList/List/';

      axios.post(url, v_http_post_data)

        .then(resp => {
          let list = resp.data;
          setListOptionDetailsList(list);
          console.log('Expiry of ListOptionDetailsList[0][0].Expiry in index.js: ' + list[0][0].Expiry);
          // CombinationList_Get();
        });
        
  }

  useEffect(() => {
    if (ListOptionDetailsList.length > 0) CombinationList_Get();
  }, [ListOptionDetailsList]);

  function SubmitAction(){

    var v_http_post_data = {
      Credential: AuthenticationService.getCurrentUser(),
      UnderlyingProductCode: UnderlyingProductCode,
      TimeStatus: TimeStatus,
    }

    let url;

    switch(ProductType){
      case 'IndexOption':
        url = sessionStorage.getItem('SiteURL') + 'api/IndexListItem/IndexCode/';
        break;
      case 'StockOption':
        url = sessionStorage.getItem('SiteURL') + 'api/StockListItem/StockCode/';
        break;
      }
    
    console.log('UnderlyingProductCode: ' + UnderlyingProductCode);

    axios.post(url, v_http_post_data)

        .then(resp => {
            var V_ListItem = resp.data;
            var V_UnderlyingProductValue = V_ListItem.Last; // Last value
            var V_UnderlyingProductValueFloat = parseFloat(V_UnderlyingProductValue);
            setUnderlyingProductValue(V_UnderlyingProductValueFloat);
            if (V_UnderlyingProductValueFloat > 0) ListOptionDetailsList_Get();
        });

  }

  // useEffect(() => {
  //   if (UnderlyingProductValue > 0) ListOptionDetailsList_Get();
  // }, [UnderlyingProductValue]);

  return (

    <div>

    <ButtonAppBar />
    {/* <Box pl={0} pr={0} m={0}> */}
    <Container>

        <CurrentHistorical 
          ProductType_Callback={setProductType} 
          UnderlyingProductCode_Callback={setUnderlyingProductCode} 
          Expiry_Callback={setExpiry} 
          StrikeInterval_Callback={setStrikeInterval} 
          TimeStatus_Callback={setTimeStatus}
          Expiry={Expiry} 
          NoExpiry={false}
        />

        <Options4Submit 
          RequireUnderlying_Callback={setRequireUnderlying}
          OutlookUnderlying_Callback={setOutlookUnderlying}
          ProfitPotential_Callback={setProfitPotential}
          LossPotential_Callback={setLossPotential}
          CreditDebit_Callback={setCreditDebit}
          Leg_Callback={setLeg}
          Left_Callback={setLeft} 
          Center_Callback={setCenter} 
          Right_Callback={setRight} 
          BaseContract_Callback={setBaseContract}
          BaseContract={BaseContract}
        />

        <Button variant="contained" color={SubmitButtonColor} fullWidth="true" onClick={SubmitAction}>{SubmitButtonLabel}</Button>
        <br/><br/>




<MaterialTableOptionPlanner 
          Left={Left_0}
          Center={Center_0}
          Right={Right_0}
          ProductType={ProductType_0}
          ProductType4UnderlyingProductValue={ProductType4UnderlyingProductValue_0}
          UnderlyingProductCode={UnderlyingProductCode_0}
          UnderlyingProductValue={UnderlyingProductValue_0}
          StrikeInterval={StrikeInterval_0}
          Expiry={Expiry_0}
          BaseContract={BaseContract_0}
          ListOptionDetailsList={ListOptionDetailsList_0}
          RefreshDateTime={RefreshDateTime_0}
          CombinationList={CombinationList_0}
          CombinationListLength={CombinationListLength_0}
        />




 

      <br/><br/><br/>

    </Container>
    {/* </Box> */}
    </div>

  )

}