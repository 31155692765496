// MaterialTableOptionPlanner.js

import { BrowserRouter, Route } from "react-router-dom";
import React from 'react';
import ReactDOM from 'react-dom';
import { useRoutes, A, navigate } from "hookrouter";
import axios from 'axios';
import { useState, useEffect, createContext, useContext } from "react";

import { Button, InputLabel } from '@material-ui/core';
import MaterialTable from "material-table";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { sizing } from '@material-ui/system';
import Container from '@material-ui/core/Container';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FolderIcon from '@material-ui/icons/Folder';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { DataGrid } from '@material-ui/data-grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';

import CombinationListItem from './CombinationListItem.js';
import AuthenticationService from '../AuthenticationService.js';
import Options4Display from "./Options4Display.js";
import ButtonAppBar from "./ButtonAppBar.js";
import { LongUnderlyingProductValue, Number2Dollar, FormattedNumber, CheckAuthenticated } from './Common.js';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },
//   menuButton: {
//     marginRight: theme.spacing(0),
//   },
//   title: {
//     flexGrow: 1,
//   },
// }));

const useStyles = makeStyles({
  table: {
    // margin: 10,
    // padding: 12
  }
});

export default function MaterialTableOptionPlanner(props){

  const { 
    CombinationListLength,
    ListOptionDetailsList,
    Left,
    Center,
    Right,
    ProductType,
    ProductType4UnderlyingProductValue,
    UnderlyingProductCode,
    UnderlyingProductValue,
    StrikeInterval,
    Expiry,
    BaseContract,
    RefreshDateTime,
    CombinationList,
  } = props;

  // const [CombinationDetails, setCombinationDetails] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(2);

  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return(

    <div> 

      Total: {CombinationList.length} 
      <br/><br/>


      <Container disableGutters={true} maxWidth="lg">

        {/* <TableContainer component={Paper}> */}

          <Table aria-label="collapsible table" className={classes.table}>
      
            <TableBody>
              {CombinationList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((M_CombinationListItem) => (                  
                  <CombinationListItem 
                    CombinationListItem={M_CombinationListItem} 
                    ListOptionDetailsList={ListOptionDetailsList} 
                    Left={Left} 
                    Center={Center} 
                    Right={Right} 
                    ProductType={ProductType} 
                    ProductType4UnderlyingProductValue={ProductType4UnderlyingProductValue} 
                    UnderlyingProductCode={UnderlyingProductCode} 
                    UnderlyingProductValue={UnderlyingProductValue} 
                    StrikeInterval={StrikeInterval} 
                    Expiry={Expiry} 
                    BaseContract={BaseContract} 
                    RefreshDateTime={RefreshDateTime}
                  />                      
                ))
              }
            </TableBody>
      
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[1, 2, 5, 10, 25, 100]}
                  count={CombinationListLength}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter> 

          </Table>

        {/* </TableContainer> */}
    
      </Container>

  </div>         
  )

}

// class MaterialTableOptionPlanner123 extends React.Component {

//   constructor(props){

//     super(props);

//     this.state ={
//       ProductType: '',
//       ProductType4UnderlyingProductValue: '',
//       UnderlyingProductCode: '',
//       UnderlyingProductValue: 0,
//       StrikeInterval: 0,
//       Expiry: '',
//       // ListOptionDetailsList: [],
//       CombinationList: [],
//       CombinationListLength: 0,
//       //CombinationListDetails: {CombinationList: [], ListOptionDetailsList: []},
//       stocks: [],
//       c: [],
//       RequireUnderlying: false, // default value
//       OutlookUnderlying: '', // default value
//       ProfitPotential: '',  // default value
//       LossPotential: '',
//       CreditDebit: '',
//       Leg: 0,
//       Left: '',
//       Center: '',
//       Right: '',
//       RefreshDate: '',
//       RefreshHour: '',
//       RefreshMinute: '',
//       TimeStatus: 'Current',
//       DateList: [],
//       BaseContract: 1,
//     };

//     this.Options4SubmitRef = React.createRef();

//   }

//    componentDidMount() {
//     CheckAuthenticated();    
//   }

//    render(){

//     // console.log("render() of index.js");
//     console.log('Length of CombinationList: ' + this.props.CombinationListLength);    

//     const columns = [
//       {
//         render: rowData => <CombinationListItem rowData={rowData} ListOptionDetailsList={this.props.ListOptionDetailsList} Left={this.props.Left} Center={this.props.Center} Right={this.props.Right} ProductType={this.props.ProductType} ProductType4UnderlyingProductValue={this.props.ProductType4UnderlyingProductValue} UnderlyingProductCode={this.props.UnderlyingProductCode} UnderlyingProductValue={this.props.UnderlyingProductValue} StrikeInterval={this.props.StrikeInterval} Expiry={this.props.Expiry} BaseContract={this.props.BaseContract} RefreshDateTime={this.props.RefreshDateTime}/>,
//       },
//     ];

//     return (

//       <div>

//       <ButtonAppBar />

//       <Container>
//           <br/>
//           Total: {this.props.CombinationListLength}
//           <br/><br/><br/>

//           <MaterialTable
//             columns={columns}
//             data={this.props.CombinationList} // list
//             options={{
//               showTitle: false,
//               header: false,
//               search: false,
//               paging: true,
//               filtering: false,
//               exportButton: false,
//               toolbar: false
//             }}
//           />

//       </Container>

//       </div>
//     )
//   }
// }