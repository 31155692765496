// BidAskDisplay.js

import { BrowserRouter, Route } from "react-router-dom";
import React from 'react';
import axios from 'axios';
import { useState } from "react";
import ReactDOM from 'react-dom';
import MaterialTable from "material-table";
import { useRoutes, A, navigate } from "hookrouter";
import { w3cwebsocket as W3CWebSocket } from 'websocket';

import { InputLabel } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { sizing } from '@material-ui/system';
import Container from '@material-ui/core/Container';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FolderIcon from '@material-ui/icons/Folder';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Tooltip from '@material-ui/core/Tooltip';
import { DataGrid } from '@material-ui/data-grid';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';

import AuthenticationService from '../AuthenticationService.js';
import { LongUnderlyingProductValue, Number2Dollar, FormattedNumber, CheckAuthenticated, ButtonAppBar, LongExpiry, ShortExpiry, Expiry_Get, date2string, UnderlyingProductValue_Get, getResponse, GroupSummaryCostValueSum, SetBidAskValue, LongFormattedValue, BidDetails, AskDetails } from './Common.js';

function BidAskDisplay(props){

    const { 
      OptionList, 
      OptionDetailsListItem 
    } = props;

    var V_BidDetails = BidDetails(OptionList, OptionDetailsListItem);
    let _bid = V_BidDetails.Bid;

    var V_AskDetails = AskDetails(OptionList, OptionDetailsListItem);
    let _ask = V_AskDetails.Ask;
    
    var V_Contract;

    switch(OptionDetailsListItem.Status){
    
      case 'Pending':

        V_Contract = OptionDetailsListItem.RefCostContract;
      
        switch(OptionDetailsListItem.OptionType){

          case 'LongCall':
          case 'LongPut':

            OptionDetailsListItem.RefCostPrice = _ask;
            OptionDetailsListItem.RefValuePrice = _bid;

            break;

          case 'ShortCall':
          case 'ShortPut':

            OptionDetailsListItem.RefCostPrice = _bid;
            OptionDetailsListItem.RefValuePrice = _ask;

            break;

        }

        break;

      case 'Opened':

        V_Contract = OptionDetailsListItem.RefValueContract;

        switch(OptionDetailsListItem.OptionType){

          case 'LongCall':
          case 'LongPut':

            OptionDetailsListItem.RefValuePrice = _bid;

            break;

          case 'ShortCall':
          case 'ShortPut':

            OptionDetailsListItem.RefValuePrice = _ask;

            break;

        }

        break;

    }

    if (V_Contract > 0){
    
      return(
    
        <div>
    
          <Grid container spacing={2} align="left">
            <Grid item xs={12} lg={12}>
              Ask: {LongFormattedValue(_ask, 0, false)}<br/>{date2string(V_AskDetails.RevisionDateTimeHKAsk, true, false, false)}
            </Grid>
            {/* <Grid item xs={12} lg={12}>
              
            </Grid> */}
          </Grid>
    
        </div>
    
      )
    
    }
    else if (V_Contract == 0){
    
      return(
    
        <div>
    
          <Grid container spacing={0} align="left">
            <Grid item xs={6} lg={6}>
              Bid: {LongFormattedValue(_bid, 0, false)}<br/>{date2string(V_BidDetails.RevisionDateTimeHKBid, true, false, false)}
            </Grid>
            <Grid item xs={6} lg={6}>
              Ask: {LongFormattedValue(_ask, 0, false)}<br/>{date2string(V_AskDetails.RevisionDateTimeHKAsk, true, false, false)}
            </Grid>
          </Grid>
    
        </div>
    
      )
    
    }
    else{
    
      return(
    
        <div>
    
          <Grid container spacing={2} align="left">
            <Grid item xs={12} lg={12}>
              Bid: {LongFormattedValue(_bid, 0, false)}<br/>{date2string(V_BidDetails.RevisionDateTimeHKBid, true, false, false)}
            </Grid>
            {/* <Grid item xs={6} lg={6}>
              
            </Grid> */}
          </Grid>
    
        </div>
    
      )
    
    }
    
} 

export default BidAskDisplay;