// MyList.js

import { BrowserRouter, Route } from "react-router-dom";
import React from 'react';
import { useState, useEffect, createContext, useContext } from "react";
import axios from 'axios';
import ReactDOM from 'react-dom';
import MaterialTable from "material-table";
import { useRoutes, A, navigate } from "hookrouter";
import { w3cwebsocket as W3CWebSocket } from 'websocket';

import { Button, InputLabel } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { sizing } from '@material-ui/system';
import Container from '@material-ui/core/Container';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FolderIcon from '@material-ui/icons/Folder';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { DataGrid } from '@material-ui/data-grid';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import SnackbarContent from '@mui/material/SnackbarContent';

import AuthenticationService from '../AuthenticationService.js';
import CurrentHistorical from './CurrentHistorical.js';
import OptionSummary from './OptionSummary.js';
import HeadTable from './HeadTable.js';
import UnderlyingProduct1 from './UnderlyingProduct1.js';
import Snackbars from './Snackbars.js';
import ButtonAppBar from "./ButtonAppBar.js";
import { ChartDataDateTimeHKContext, IndexStockListItemContext } from '../Context.js';
import { LongUnderlyingProductValue, Number2Dollar, FormattedNumber, CheckAuthenticated, LongExpiry, ShortExpiry, Expiry_Get, date2string, UnderlyingProductValue_Get, getResponse, GroupSummaryCostValueSum, SetBidAskValue, GroupNumberList_Update, OnMessageIndex, OnMessageIndexOption } from './Common.js';

export default function MyList(){

  const [GroupNumberList, setGroupNumberList] = useState([]);
  const [GroupNumberListActive, setGroupNumberListActive] = useState('true');
  const [TimeStatus, setTimeStatus] = useState('Current');

  const [ShowSnackbar, setShowSnackbar] = useState({GroupNumberList: false, Refresh: false});
  const [SnackbarMessage, setSnackbarMessage] = useState({GroupNumberList: '', Refresh: ''});
  const [TriggerSnackbar, setTriggerSnackbar] = useState({GroupNumberList: false, Refresh: false});
  const [SnackbarColor, setSnackbarColor] = useState({GroupNumberList: 'warning', Refresh: 'success'});

  const [ProductType, setProductType] = useState('IndexOption');
  const [UnderlyingProductType, setUnderlyingProductType] = useState('Index');
  const [UnderlyingProductCode, setUnderlyingProductCode] = useState('HK.HSI');
  const [OptionList, setOptionList] = useState('');

  const [Expiry, setExpiry] = useState('');
  const [StrikeInterval, setStrikeInterval] = useState('');

  const [WSClient, setWSClient] = useState('');
  const [WebSocketOpen, setWebSocketOpen] = useState('WebSocket');
  const [WebSocketOpenBgColor, setWebSocketOpenBgColor] = useState('warning');
  const [WebSocketConsoleMessage, setWebSocketConsoleMessage] = useState('Console Message');
  const [UnderlyingProductLast, setUnderlyingProductLast] = useState('');
  
  const [Auto, setAuto] = useState(false);
  const [IndexStockListItem, setIndexStockListItem] = useState({Last: 0});
  const [UnderlyingProductCode2, setUnderlyingProductCode2] = useState('');

  function IndexStockListItem_Get(){

    var v_http_post_data = {
      Credential: AuthenticationService.getCurrentUser(),
      UnderlyingProductCode: UnderlyingProductCode,
      TimeStatus: TimeStatus,
    }

    var url;
    switch(UnderlyingProductType){
      case 'Index':
          url = sessionStorage.getItem('SiteURL') + 'api/IndexListItem/IndexCode/';
          break;  
      case 'Stock':
          url = sessionStorage.getItem('SiteURL') + 'api/StockListItem/StockCode/';
          break;    
    }    

    axios.post(url, v_http_post_data)

    .then(resp => {

      var V_IndexStockListItem = resp.data;
      V_IndexStockListItem.ProductType = UnderlyingProductType;
      
      setIndexStockListItem(V_IndexStockListItem);
      setUnderlyingProductLast(V_IndexStockListItem.Last);
      // this.setState({IndexStockListItem: V_IndexStockListItem});

    });

  }

  function SubmitAction(){
    OptionList_Get();
    setUnderlyingProductCode2(UnderlyingProductCode);
    // this.setState({UnderlyingProductCode2: this.state.UnderlyingProductCode});
    // this.UnderlyingProductValue_Get(this.state.ProductType, this.state.UnderlyingProductCode, this.state.TimeStatus);
  }

  function OptionListItem_Callback(P_OptionListItem){

    var V_Testing = true;
    
    var V_OptionList = OptionList;
    var _index = V_OptionList.findIndex(x => x.ProductCode === P_OptionListItem.ProductCode);
      
    if (_index !== -1){              
    
      if (V_Testing){
        V_OptionList[_index].Bid = P_OptionListItem.Bid;
        V_OptionList[_index].Ask = P_OptionListItem.Ask;
        V_OptionList[_index].RevisionDateTimeHKBid = P_OptionListItem.RevisionDateTimeHKBid;
        V_OptionList[_index].RevisionDateTimeHKAsk = P_OptionListItem.RevisionDateTimeHKAsk;
      }          
      else{
        V_OptionList[_index] = P_OptionListItem;
      }
    
    }
      
    setOptionList(V_OptionList);
    // this.setState({OptionList: V_OptionList});
      
  }

  // function AutoAction(){
  //   setAuto(!Auto);            
  // }

function WebSocket_Open(P_Message, P_WebSocketOpenBgColor){

  var V_WebSocketURL = sessionStorage.getItem('WebSocketURL');
  const _ws_client = new W3CWebSocket(V_WebSocketURL);      

  _ws_client.onopen = () => {

    // var V_WebSocketOpen = {Message: '', DateTime: ''};

    // V_WebSocketOpen.Message = P_Message;
    // V_WebSocketOpen.DateTime = new Date().toLocaleTimeString('en-US', { hour12: false, hour: "numeric", minute: "numeric", second: "numeric"});
    // V_WebSocketOpen.DateTime = '(' + V_WebSocketOpen.DateTime + ')';

    var V_ConsoleMessage = P_Message;
    var V_ConsoleDateTime = new Date().toLocaleTimeString('en-US', { hour12: false, hour: "numeric", minute: "numeric", second: "numeric"});
    var V_WebSocketOpen = V_ConsoleMessage + ' (' + V_ConsoleDateTime + ')';

    setWebSocketOpen(V_WebSocketOpen);
    setWebSocketOpenBgColor(P_WebSocketOpenBgColor);
    // this.setState({WebSocketOpen: V_WebSocketOpen});

    _ws_client.onmessage = (message) => {
    
      var V_Data = JSON.parse(message.data);

      switch(V_Data.ProductType){

        case 'Index':  
          OnMessageIndex(V_Data, setIndexStockListItem, setWebSocketConsoleMessage, 'OptionTable');
          break;           

        case 'IndexOption':
          OnMessageIndexOption(V_Data, OptionListItem_Callback, setWebSocketConsoleMessage, 'OptionTable');
          break;

      }

    }

    _ws_client.onclose = () => {
      WebSocket_Open('Opened', 'error');
    };

    _ws_client.onerror = (err) => {
      console.error('WebSocket encountered error: ', err.message, 'Closing socket');
      _ws_client.close();
    };

  }

}

  useEffect(() => {

    switch(Auto){
    
      case true:
        WebSocket_Open('Opened', 'warning');
        break;

      case false:
        break;
    
    }    

    // switch(Auto){
      
    //   case true:
        
    //     WSClient.onmessage = (message) => {
          
    //       var V_Data = JSON.parse(message.data);

    //       switch(V_Data.ProductType){
        
    //         case 'Index':        
    //           OnMessageIndex(V_Data, setIndexStockListItem, setWebSocketConsoleMessage, 'MyList');
    //           break;           
        
    //         case 'IndexOption':  
    //           OnMessageIndexOption(V_Data, OptionListItem_Callback, setWebSocketConsoleMessage, 'MyList');
    //           break;

    //       }

    //     }

    //     WSClient.onclose = () => {
    //       this.WebSocket_Open('WebSocket was closed and re-opened');
    //     };

    //     WSClient.onerror = (err) => {
    //       console.error('WebSocket encountered error: ', err.message, 'Closing socket');
    //       WSClient.close();
    //     };
        
    //     break;
        
    //   case false:          
        
    //     break;
        
    // }

  }, [Auto]);

  useEffect(() => {

    // WebSocket_Open();

    // var V_WebSocketURL = sessionStorage.getItem('WebSocketURL');
    // const _ws_client = new W3CWebSocket(V_WebSocketURL);  

    // _ws_client.onopen = () => {
    //   setWSClient(_ws_client);
    // }
    
    switch(ProductType){
      case 'IndexOption':
        setUnderlyingProductType('Index');
        break;
      case 'StockOption':
        setUnderlyingProductType('Stock');
        break;
    }

    IndexStockListItem_Get();    

  }, []); // Runs only on the first render

  function OptionDetailsListItem_Callback(P_OptionDetailsListItem){

    let V_GroupNumberList = GroupNumberList;
    let V_GroupNumberListItem = V_GroupNumberList.find(x => x.Number == P_OptionDetailsListItem.GroupNumber);
    let V_OptionDetailsListItem = V_GroupNumberListItem.OptionDetailsList.find(x => x.ID == P_OptionDetailsListItem.ID);

    V_OptionDetailsListItem.CostContract = P_OptionDetailsListItem.CostContract;
    V_OptionDetailsListItem.CostShare = P_OptionDetailsListItem.CostShare;
    V_OptionDetailsListItem.CostPrice = P_OptionDetailsListItem.CostPrice;
    V_OptionDetailsListItem.TotalCostAvg = P_OptionDetailsListItem.TotalCostAvg;
    V_OptionDetailsListItem.Status = P_OptionDetailsListItem.Status;

    setGroupNumberList([...V_GroupNumberList]);
    // this.setState({GroupNumberList: V_GroupNumberList});

  }

  function GroupNumberListItemDelete_Callback(p_GroupNumberListItem){

    let _group_number_list = GroupNumberList;
    let _index = _group_number_list.findIndex(x => x.Number === p_GroupNumberListItem.Number);
    if (_index !== -1){
      _group_number_list.splice(_index, 1);
      // setGroupNumberList(_group_number_list);
      setGroupNumberList([..._group_number_list]); // create a new array
      // this.setState({GroupNumberList: _group_number_list}); 
    }

  }

  function GroupNumberListItem_Callback(P_GroupNumberListItem){

    let _group_number_list = GroupNumberList;
    let _index = _group_number_list.findIndex(x => x.Number === P_GroupNumberListItem.Number);
    if (_index !== -1){
      _group_number_list[_index] = P_GroupNumberListItem;
      setGroupNumberList([..._group_number_list]);
      // this.setState({GroupNumberList: _group_number_list}); 
    }

  }

  function Snackbar_Callback(P_SnackbarType){

    var V_TriggerSnackbar = TriggerSnackbar;

    switch(P_SnackbarType){
      case 'GroupNumberList':
        V_TriggerSnackbar.GroupNumberList = false;
        break;
      case 'Refresh':
        V_TriggerSnackbar.Refresh = false;
        break;  
    }
    
    setTriggerSnackbar(V_TriggerSnackbar);
    // this.setState({TriggerSnackbar: V_TriggerSnackbar});
    
  }

  function ActiveAllExpiredUpdateAction(){

    var V_AllExpired;

    switch(GroupNumberListActive){
      case 'true':
        V_AllExpired = 'false';
        break;
      case 'false':
        V_AllExpired = 'true';
        break;
    }

    var v_http_post_data = {
      Credential: AuthenticationService.getCurrentUser(),
      AllExpired: V_AllExpired,
    }
    
    const url = sessionStorage.getItem('SiteURL') + '/api/GroupNumberList/ActiveAllExpiredUpdate/';

    axios.post(url, v_http_post_data)

    .then(resp => {
      
      let _return_status = resp.data;

      var V_ShowSnackbar = ShowSnackbar;
      V_ShowSnackbar.Refresh = true;

      var V_SnackbarMessage = SnackbarMessage;
      V_SnackbarMessage.Refresh = 'Refresh was completed';

      var V_TriggerSnackbar = TriggerSnackbar;
      V_TriggerSnackbar.Refresh = true;

      setShowSnackbar(V_ShowSnackbar);
      setSnackbarMessage(V_SnackbarMessage);
      setTriggerSnackbar(V_TriggerSnackbar);

      // this.setState({
      //   ShowSnackbar: V_ShowSnackbar, 
      //   SnackbarMessage: V_SnackbarMessage, 
      //   TriggerSnackbar: V_TriggerSnackbar
      // });

      GroupNumberList_Get(true);

    })

  }

  function OptionList_Get(){

    var v_http_post_data = {
      Credential: AuthenticationService.getCurrentUser(),
      ProductType: ProductType,
      UnderlyingProductCode: UnderlyingProductCode,
      TimeStatus: TimeStatus,
    }
    
    const url = sessionStorage.getItem('SiteURL') + '/api/OptionList/ByUnderlyingProductCode/';

    axios.post(url, v_http_post_data)

        .then(resp => {

          let _OptionList = resp.data;

          setOptionList(_OptionList);
          GroupNumberList_Get(false);

          // this.setState({OptionList: _OptionList}, () => {    
          //   this.GroupNumberList_Get(false);
          // });

      });

  }

  // function WebSocketConsoleMessage_Show(){

  //   // if (WebSocketConsoleMessage.length > 0){

  //     return(
  //       // <React.Fragment>
  //         <Snackbar open={true} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
  //           <Alert icon={false} severity={'info'}>
  //             {WebSocketConsoleMessage}
  //           </Alert>
  //         </Snackbar>
  //       // </React.Fragment>
  //     )

  //   // }

  //   // if (WebSocketConsoleMessage.length > 0){

  //   //   return(
  //   //     <React.Fragment>
  //   //       <Snackbar open={true} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
  //   //         <Alert icon={false} severity={'info'}>
  //   //           {WebSocketConsoleMessage}
  //   //         </Alert>
  //   //       </Snackbar>
  //   //     </React.Fragment>
  //   //   )

  //   // }
  //   // else{

  //   //   return(
  //   //     <React.Fragment>
  //   //     </React.Fragment>
  //   //   )

  //   // }

  // }

  function GroupNumberList_Get(P_SnackbarDisabled){

    var v_http_post_data = {
      Credential: AuthenticationService.getCurrentUser(),
      GroupNumberListActive: GroupNumberListActive,
      TimeStatus: TimeStatus,
    }
    
    const url = sessionStorage.getItem('SiteURL') + '/api/GroupNumberList/Get/';
  
    axios.post(url, v_http_post_data)
  
    .then(resp => {

      var V_GroupNumberList = resp.data;
      setGroupNumberList(V_GroupNumberList);

      if (P_SnackbarDisabled == false){
  
        if (V_GroupNumberList.length == 0){

          var V_ShowSnackbar = ShowSnackbar;
          V_ShowSnackbar.GroupNumberList = true;

          var V_SnackbarMessage = SnackbarMessage;
          V_SnackbarMessage.GroupNumberList = 'No record found';

          var V_TriggerSnackbar = TriggerSnackbar;
          V_TriggerSnackbar.GroupNumberList = true;

          setShowSnackbar(V_ShowSnackbar);
          setSnackbarMessage(V_SnackbarMessage);
          setTriggerSnackbar(V_TriggerSnackbar);

          // this.setState({
          //   ShowSnackbar: V_ShowSnackbar, 
          //   SnackbarMessage: V_SnackbarMessage, 
          //   TriggerSnackbar: V_TriggerSnackbar
          // });

        }

        else{

          var V_ShowSnackbar = ShowSnackbar;
          V_ShowSnackbar.GroupNumberList = false;

          var V_SnackbarMessage = SnackbarMessage;
          V_SnackbarMessage.GroupNumberList = '';

          var V_TriggerSnackbar = TriggerSnackbar;
          V_TriggerSnackbar.GroupNumberList = true;

          setShowSnackbar(V_ShowSnackbar);
          setSnackbarMessage(V_SnackbarMessage);
          setTriggerSnackbar(V_TriggerSnackbar);

          // this.setState({
          //   ShowSnackbar: V_ShowSnackbar, 
          //   SnackbarMessage: V_SnackbarMessage, 
          //   TriggerSnackbar: V_TriggerSnackbar
          // });

        }  
      }
              
    })
  
  }   

  GroupNumberList_Update(OptionList, GroupNumberList, IndexStockListItem.Last);

  return (    

    <div>

      <IndexStockListItemContext.Provider value={IndexStockListItem}>

        <ButtonAppBar />                 

        <Container maxWidth="lg">

        <CurrentHistorical 
          ProductType_Callback={setProductType} 
          UnderlyingProductCode_Callback={setUnderlyingProductCode} 
          Expiry_Callback={setExpiry} 
          StrikeInterval_Callback={setStrikeInterval} 
          TimeStatus_Callback={setTimeStatus}
          Expiry={Expiry}
          NoExpiry={true} 
        />

        <Grid container spacing={2}>
          <Grid item align="left" xs={8} lg={8}>
            <Box display="flex" justifyContent="center">
              <RadioGroup row value={GroupNumberListActive} onChange={e => setGroupNumberListActive(e.target.value)} >
                <FormControlLabel value='true' control={<Radio />} label="Active"/>
                <FormControlLabel value='false' control={<Radio />} label="Inactive"/>
              </RadioGroup>
            </Box>
          </Grid>
          <Grid item align="right" xs={4} lg={4}>
            <Button variant="contained" color="secondary" size="small" onClick={e => ActiveAllExpiredUpdateAction(e)}>更新</Button>
          </Grid>
        </Grid>               
        
        <br/><br/>

        <Grid container spacing={2}>
          <Grid item align="left" xs={9} lg={9}>
            <Button variant="contained" color="primary" fullWidth="true" onClick={e => SubmitAction(e)}>確定</Button>
          </Grid>
          <Grid item align="right" xs={3} lg={3}>
            <FormControlLabel control={<Checkbox />} label="Auto" onClick={() => setAuto(!Auto)}/>
          </Grid>
          {/* <Grid item align="right" xs={12} lg={12}>
            {WebSocketConsoleMessage}
          </Grid> */}
        </Grid>               
        <br/>
        <UnderlyingProduct1 
          // Auto={Auto}
          ProductType={'Index'} 
          UnderlyingProductCode={'HK.HSI'} 
          TimeStatus={'Current'}
          OptionList={OptionList}
          // IndexStockListItem={this.state.IndexStockListItem}
        />

        <br/>
        {/* <SnackbarContent style={{position: 'fixed'}} message={WebSocketConsoleMessage} /> */}

        {/* <WebSocketConsoleMessage_Show /> */}

        <Snackbar open={true} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          <Alert icon={false} severity={WebSocketOpenBgColor}>
            {WebSocketOpen}
          </Alert>
        </Snackbar>

        <Snackbar open={true} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <Alert icon={false} severity={'info'}>
            {WebSocketConsoleMessage}
          </Alert>
        </Snackbar>

        Total: {GroupNumberList.length} 

        </Container>
        <br/>
        <OptionSummary
          OptionList={OptionList}
          GroupNumberList={GroupNumberList}
          GroupNumberList_Get={GroupNumberList_Get}
          UnderlyingProductLast={UnderlyingProductLast}
          GroupNumberListItem_Callback={GroupNumberListItem_Callback}
          GroupNumberListItemDelete_Callback={GroupNumberListItemDelete_Callback}
          // GroupNumberListItemDelete_PageUpdate
          OptionDetailsListItem_Callback={OptionDetailsListItem_Callback}
        />
        <br/><br/>           

        </IndexStockListItemContext.Provider>

        <Stack spacing={2} sx={{ width: '100%' }}>

          <Snackbars
            SnackbarType={'GroupNumberList'}
            ShowSnackbar={ShowSnackbar.GroupNumberList}
            SnackbarMessage={SnackbarMessage.GroupNumberList}
            SnackbarColor={SnackbarColor.GroupNumberList}
            TriggerSnackbar={TriggerSnackbar.GroupNumberList}
            Snackbar_Callback={Snackbar_Callback}
          />

          <Snackbars
            SnackbarType={'Refresh'}
            ShowSnackbar={ShowSnackbar.Refresh}
            SnackbarMessage={SnackbarMessage.Refresh}
            SnackbarColor={SnackbarColor.Refresh}
            TriggerSnackbar={TriggerSnackbar.Refresh}
            Snackbar_Callback={Snackbar_Callback}
          />

          

        </Stack>

      </div>

  )

}

// export default MyList;