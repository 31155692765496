// Historical.js

import React from 'react';
import { useState, useEffect, createContext, useContext } from "react";

import { Button, InputLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

function Historical(props){

  const { 
    RefreshAction,
    TimeStatus, 
    DateList
  } = props;

  const [RefreshDate, setRefreshDate] = useState('');
  const [RefreshHour, setRefreshHour] = useState('09');
  const [RefreshMinute, setRefreshMinute] = useState('15');

  function onClick_RefreshAction(){
    RefreshAction(RefreshDate, RefreshHour, RefreshMinute);
  }

  function Select_Date(){

    return(
      <Grid item xs={6}>
      <FormControl style={{ width: '100%' }}>
        <InputLabel id="DateLabel">日期</InputLabel>
        <Select native='true' id="Date" labelId="DateLabel" value={RefreshDate} style={{ width: '100%' }} onChange={e => setRefreshDate(e.target.value)}>
          {DateList}
        </Select>
      </FormControl>
    </Grid>
    )

  }

  function Select_Hour(){

    return(
      <Grid item xs={3}>
      <FormControl style={{ width: '100%' }}>
      <InputLabel id="HourLabel">時</InputLabel>
        <Select native='true' id="Hour" labelId="HourLabel" defaultValue={RefreshHour} style={{ width: '100%' }} onChange={e => setRefreshHour(e.target.value)}>
          <option key='0' value='09'>09</option>
          <option key='1' value='10'>10</option>
          <option key='2' value='11'>11</option>
          <option key='3' value='12'>12</option>
          <option key='4' value='13'>13</option>
          <option key='5' value='14'>14</option>
          <option key='6' value='15'>15</option>
          <option key='7' value='16'>16</option>
        </Select>
      </FormControl>
    </Grid>
    )

  }

  function Select_Minute(){

    return(
      <Grid item xs={3}>
      <FormControl style={{ width: '100%' }}>
      <InputLabel id="MinuteLabel">分</InputLabel>
        <Select native='true' id="Minute" labelId="MinuteLabel" defaultValue={RefreshMinute} style={{ width: '100%' }} onChange={e => setRefreshMinute(e.target.value)}>
          <option key='0' value='00'>00</option>
          <option key='1' value='15'>15</option>
          <option key='2' value='30'>30</option>
          <option key='3' value='45'>45</option>                     
        </Select>
      </FormControl>
    </Grid>
    )

  }

  useEffect(() => {
  if (DateList.length > 0) setRefreshDate(DateList[0].props.value);
  }, [DateList]);

  return(    

    <div>

        <div style={{ display: TimeStatus == 'Historical' ? 'block' : 'none'}}>

        <Grid container spacing={1}>
          <Select_Date />
          <Select_Hour />
          <Select_Minute />
        </Grid>

        <br/>
        <Button variant="contained" color="secondary" fullWidth="true" onClick={e => onClick_RefreshAction(e)}>更新</Button>
        <br/><br/>
        </div>

    </div>
    
  )

}

export default Historical;