// OptionSummaryListItemDetails.js

import React from 'react';

import Grid from '@material-ui/core/Grid';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import { red, green } from '@mui/material/colors';

import { Profit, date2string, Rounding } from './Common.js';

function ProductCodeChip(P_OptionDetailsListItem, P_UnderlyingProductLast){

    var V_ChipColor;
    var V_StrikeLastDiff;

    switch(P_OptionDetailsListItem.CallPut){
        case 'C':
            V_ChipColor = 'success';
            V_StrikeLastDiff = P_OptionDetailsListItem.Strike - Rounding(P_UnderlyingProductLast, 0);
            break;
        case 'P':
            V_ChipColor = 'error';
            V_StrikeLastDiff = Rounding(P_UnderlyingProductLast, 0) - P_OptionDetailsListItem.Strike;
            break;
    }

    var V_Contract;
    switch(P_OptionDetailsListItem.Status){
        case 'Pending':
            V_Contract = P_OptionDetailsListItem.RefCostContract;
            break;
        case 'Opened':
        case 'Closed': 
        case 'Closed2Expired': 
        case 'Opened2Expired':  
            V_Contract = P_OptionDetailsListItem.CostContract;
            break;
    }
    
    const AvatarRedBgColor={backgroundColor: red[100]};
    const AvatarGreenBgColor={backgroundColor: green[100]};
    var AvatarBgColor;

    var V_ContractString;    

    if (V_Contract > 0){
        V_ContractString = '+' + V_Contract;
        AvatarBgColor = AvatarGreenBgColor;
    }
    else{
        V_ContractString = V_Contract;
        AvatarBgColor = AvatarRedBgColor;
    }    

    if (V_Contract > 0){
        
    }
    else{
        
    }

    var V_Color;
    var V_DateTime;    

    switch(P_OptionDetailsListItem.Status){
      case 'Pending':
        V_Color = 'primary';
        V_DateTime = date2string(P_OptionDetailsListItem.DateTimeHKPending, false, false, true);
        break;
      case 'Opened':
        V_Color = 'secondary';
        V_DateTime = date2string(P_OptionDetailsListItem.DateTimeHKOpened, false, false, true);
        break;
      case 'Closed':
        V_Color = 'warning';
        V_DateTime = date2string(P_OptionDetailsListItem.DateTimeHKClosed, false, false, true);
        break;
      case 'Opened2Expired':
        V_Color = 'info';
        V_DateTime = date2string(P_OptionDetailsListItem.DateTimeHKOpened2Expired, false, false, true);
        break;          
      case 'Closed2Expired':
        V_Color = 'success';
        V_DateTime = date2string(P_OptionDetailsListItem.DateTimeHKClosed2Expired, false, false, true);
        break;
    }

    return(
        <React.Fragment>
            <Grid item xs={12} lg={12}>
                <Chip avatar={<Avatar sx={AvatarBgColor}>{V_ContractString}</Avatar>} label={P_OptionDetailsListItem.ProductCode} color={V_ChipColor}/>
                <br/>
                {P_OptionDetailsListItem.Status} ({V_StrikeLastDiff})
            </Grid>            
        </React.Fragment>
    )

}

export default function OptionSummaryListItemDetails(props){

    const { 
        GroupNumberListItem,
        UnderlyingProductLast,
      } = props;

    return (

        <React.Fragment>

            <Grid container spacing={1}>
                <Grid item xs={12} lg={12} align='right'>
                    {Profit(GroupNumberListItem)}
                </Grid> 
                <Grid item xs={12} lg={12}>
                    {GroupNumberListItem.Number}
                </Grid>
                <Grid item xs={12} lg={12}>
                    {GroupNumberListItem.GroupTitle}
                </Grid>                                 
                {GroupNumberListItem.OptionDetailsList.map((M_OptionDetailsListItem) => (
                    ProductCodeChip(M_OptionDetailsListItem, UnderlyingProductLast)
                ))}
           
            </Grid>

        </React.Fragment>

    )

}