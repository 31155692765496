// StrikeReferenceLines.js

import React from 'react';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';

import AuthenticationService from '../AuthenticationService.js';
import { LongUnderlyingProductValue, Number2Dollar, FormattedNumber, CheckAuthenticated, ButtonAppBar, LongExpiry, ShortExpiry, Expiry_Get, date2string, UnderlyingProductValue_Get, getResponse, GroupSummaryCostValueSum, SetBidAskValue } from './Common.js';

function StrikeReferenceLines_Get(UnderlyingProductCode, YMin, YMax, OptionList, StrikeReferenceLines_Callback){

    if (YMin > 0){

        var distinct_strike_list = [...new Set(OptionList.map(c => c.Strike))];
        distinct_strike_list = distinct_strike_list.sort((a, b) => a - b);
    
        var V_Index_YMax = distinct_strike_list.findIndex(c => c > YMax);
        var V_Index_YMin = distinct_strike_list.findLastIndex(c => c < YMin);
    
        var V_ActiveDistinctStrikeList = [];
        for (let i = V_Index_YMin; i < V_Index_YMax + 1; i++) {
            V_ActiveDistinctStrikeList.push(distinct_strike_list[i]);
        }
    
        var V_Lines = [];
         V_ActiveDistinctStrikeList.map((e, key) => {
            V_Lines.push(<ReferenceLine y={e} label={{ value: LongUnderlyingProductValue("Index", e), angle: 0, fontSize: 12, position: 'insideTopRight', fill: 'lightgrey'}} stroke="lightgrey"/>);
        });
    
        // return V_Lines;
        StrikeReferenceLines_Callback(V_Lines);

    }

}

export default StrikeReferenceLines_Get;