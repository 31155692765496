// CombinationListItem.js

import React from 'react';
import { useState, useEffect, createContext, useContext } from "react";

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import CombinationDetails2 from './CombinationDetails2.js';
import axios from 'axios';
import Box from '@material-ui/core/Box';
import { Alert, AlertTitle } from '@material-ui/lab';

import Tooltip from '@material-ui/core/Tooltip';
import { Button, InputLabel } from '@material-ui/core';

import { LongUnderlyingProductValue, LongExpiry } from './Common.js';

function LeftRightFieldValue(field, value){

  return(
  <Grid container spacing={1}>
    <Grid item xs={6} lg={6}>
      {field}
    </Grid>
    <Grid item xs={6} lg={6}>
      <Box display="flex" justifyContent="flex-end">{value}</Box>
    </Grid>
  </Grid>
  );

}

function AverageWidth(left, center, right){

  let w = 0;

  if (left) w += 1;
  if (center) w += 1;
  if (right) w += 1;

  switch(w){
    case 0:
      return 0;
    case 1:
      return 6;
    case 2:
      return 4;
    case 3:
      return 3;
  }

}

export default function CombinationListItem(props){

  const { 
    Left, 
    Center, 
    Right, 
    CombinationListItem, 
    UnderlyingProductCode, 
    ProductType4UnderlyingProductValue, 
    UnderlyingProductValue, 
    Expiry, 
    ProductType, 
    ListOptionDetailsList, 
    StrikeInterval, 
    RefreshDateTime 
  } = props;

  const [CombinationDetails, setCombinationDetails] = useState(null);
  // const [Domain, setDomain] = useState('');
  // const [XReferenceLines, setXReferenceLines] = useState([]);
  // const [YReferenceLines, setYReferenceLines] = useState([]);
  // const [XReferenceDots, setXReferenceDots] = useState([]);
  // const [XReferenceLineUnderlyingProductValue, setXReferenceLineUnderlyingProductValue] = useState('');
  // const [YReferenceLineZero, setYReferenceLineZero] = useState('');
  // const [Alerts, setAlerts] = useState('');
  // const [chartdata, setchartdata] = useState('');    
  // const [BaseContract, setBaseContract] = useState(1);

  function CombinationDisplay(P_Position, P_Display){
  
    let a = P_Position; // for debug only
    let b = P_Display; // for debug only
  
    if (P_Display == true) {
      return (
        <Grid item xs={12} lg={AverageWidth(Left, Center, Right)}>
          <CombinationDetails2
            CombinationID={CombinationListItem.ID} 
            ProductType={ProductType} 
            Position={P_Position}
            ListOptionDetailsList={ListOptionDetailsList} 
            UnderlyingProductCode={UnderlyingProductCode} 
            Expiry={Expiry} 
            UnderlyingProductValue={UnderlyingProductValue} 
            StrikeInterval={StrikeInterval} 
            RefreshDateTime={RefreshDateTime}
            TimeStatus={'Current'}
            GroupNumber={-1}
            REACT={true}
            // CombinationDetails={CombinationDetails}
            // CombinationDetails_Callback={setCombinationDetails}
            // Domain={Domain}
            // Domain_Callback={setDomain}
            // XReferenceLines={XReferenceLines}
            // XReferenceLines_Callback={setXReferenceLines}
            // YReferenceLines={YReferenceLines}
            // YReferenceLines_Callback={setYReferenceLines}
            // XReferenceDots={XReferenceDots}
            // XReferenceDots_Callback={setXReferenceDots}
            // XReferenceLineUnderlyingProductValue={XReferenceLineUnderlyingProductValue}
            // XReferenceLineUnderlyingProductValue_Callback={setXReferenceLineUnderlyingProductValue}
            // YReferenceLineZero={YReferenceLineZero}
            // YReferenceLineZero_Callback={setYReferenceLineZero}
            // Alerts={Alerts}
            // Alerts_Callback={setAlerts}
            // chartdata={chartdata}
            // chartdata_Callback={setchartdata}
            // BaseContract={BaseContract}
            // BaseContract_Callback={setBaseContract}
          />
        </Grid>
      );
    } else {
      return null;
    }
  
  }

  function Summary(){
  
    return(
      
        <Grid item xs={12} lg={AverageWidth(Left, Center, Right)}>
  
          <Alert severity='info' Variant='filled' icon={false}>
              <AlertTitle>{CombinationListItem.ID}</AlertTitle>
              <span>{CombinationListItem.ChineseName}</span><br/>
              <span>{CombinationListItem.Remark}</span><br/>                      
          </Alert>
          
          <br/>
          {LeftRightFieldValue('相關產品：', UnderlyingProductCode)}
          {LeftRightFieldValue('現貨價：', LongUnderlyingProductValue(ProductType4UnderlyingProductValue, UnderlyingProductValue))}
          {LeftRightFieldValue('到期日：', LongExpiry(Expiry))}                  
          {/* <br/> */}
  
        </Grid>
    )
  
  };

  return(
    
    <div>

    <Box border={1} borderColor="grey.300">

      <Box pb={0} pt={2} pl={2} pr={2} m={0}>   
      
        <Grid container>
          {Summary()}
        </Grid>
    
      </Box>

      <Box pb={2} pt={2} pl={0} pr={0} m={0}>   
      
        <Grid container>
          {CombinationDisplay('Left', Left)}
          {CombinationDisplay('Center', Center)}   
          {CombinationDisplay('Right', Right)}                             
        </Grid>
      
      </Box>

    </Box>
    
    <br/><br/><br/>

    </div>

  )

}