// UnderlyingProduct2.js

import AuthenticationService from '../AuthenticationService.js';
import { useRoutes, A, navigate } from "hookrouter";
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import axios from 'axios';
import { useState, useEffect, useContext } from "react";
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Chip from '@mui/material/Chip';
import { red } from '@mui/material/colors';
import { green } from '@mui/material/colors';

import UnderlyingProductLineChart from './UnderlyingProductLineChart.js';
import { ChartDataDateTimeHKContext, IndexStockListItemContext } from '../Context.js';
import { LongUnderlyingProductValue, Number2Dollar, FormattedNumber, CheckAuthenticated, ButtonAppBar, LongExpiry, ShortExpiry, Expiry_Get, date2string, UnderlyingProductValue_Get, getResponse, GroupSummaryCostValueSum, SetBidAskValue, UnderlyingProductDiffString_Get } from './Common.js';

export default function UnderlyingProduct2(props){

  const { 
    ChartData, 
    ProductType, 
    OptionList,
    // IndexStockListItem
  } = props;

  const IndexStockListItem = useContext(IndexStockListItemContext);

  function StrikeReferenceLines_Get(YMin, YMax){

    if (YMin > 0 & OptionList.length > 0){

        var distinct_strike_list = [...new Set(OptionList.map(c => c.Strike))];
        distinct_strike_list = distinct_strike_list.sort((a, b) => a - b);
    
        var V_Index_YMax = distinct_strike_list.findIndex(c => c > YMax);
        var V_Index_YMin = distinct_strike_list.findLastIndex(c => c < YMin);
    
        var V_ActiveDistinctStrikeList = [];
        for (let i = V_Index_YMin; i < V_Index_YMax + 1; i++) {
            V_ActiveDistinctStrikeList.push(distinct_strike_list[i]);
        }
    
        var V_Lines = [];
         V_ActiveDistinctStrikeList.map((e, key) => {
            V_Lines.push(<ReferenceLine y={e} label={{ value: LongUnderlyingProductValue("Index", e), angle: 0, fontSize: 12, position: 'insideTopRight', fill: 'lightgrey'}} stroke="lightgrey"/>);
        });
    
        return V_Lines;

    }

  }

  // function UnderlyingProductDiffString_Get(P_UnderlyingProductDiff){

  //   var V_UnderlyingProductDiffString = LongUnderlyingProductValue(ProductType, P_UnderlyingProductDiff);
  //   if (P_UnderlyingProductDiff > 0){
  //     V_UnderlyingProductDiffString = '(+' + V_UnderlyingProductDiffString + ')';
  //   }
  //   else{
  //     V_UnderlyingProductDiffString = '(' + V_UnderlyingProductDiffString + ')';
  //   }

  //   return V_UnderlyingProductDiffString;
    
  // }

  function Domain_Get(P_UnderlyingProductClose){

    var V_D;
    switch(ProductType){
        case 'Index':
            V_D = 100;
            break;  
        case 'Stock':
            V_D = 1;
            break;    
    } 

    var V_YMin = Math.min(...ChartData.map(c => c.y));
    var V_YMax = Math.max(...ChartData.map(c => c.y));     
    
  var V_UnderlyingProductCloseString = P_UnderlyingProductClose;
  var V_UnderlyingProductClose = parseFloat(V_UnderlyingProductCloseString);

    if (V_UnderlyingProductClose > 0){

      if (V_UnderlyingProductClose < V_YMin){
        V_YMin = V_UnderlyingProductClose - V_D;
      } 
      else{
        V_YMin = V_YMin - V_D;
      }

      if (V_UnderlyingProductClose > V_YMax){
        V_YMax = V_UnderlyingProductClose + V_D;
      } 
      else{
        V_YMax = V_YMax + V_D;
      }

    }
    else{
      V_YMin = V_YMin - V_D;
      V_YMax = V_YMax + V_D;
    }  

    return [V_YMin, V_YMax];

  }
  
  // var V_UnderlyingProductLastString = IndexStockListItem.Last; // Last value
  // var V_UnderlyingProductLast = parseFloat(V_UnderlyingProductLastString);

  // var V_UnderlyingProductCloseString = IndexStockListItem.Close;
  // var V_UnderlyingProductClose = parseFloat(V_UnderlyingProductCloseString);

  // var V_UnderlyingProductDiff = V_UnderlyingProductLast - V_UnderlyingProductClose;    
  // var V_UnderlyingProductDiffString = UnderlyingProductDiffString_Get('Index', V_UnderlyingProductDiff);

  // var V_RevisionDateTimeHKLast = IndexStockListItem.RevisionDateTimeHKLast;    

  var V_Domain = Domain_Get(IndexStockListItem.Close);

  var V_StrikeReferenceLines = StrikeReferenceLines_Get(V_Domain[0], V_Domain[1]);

    return(
        
      <div>

        <UnderlyingProductLineChart

          ChartData={ChartData} 
          Domain={V_Domain} 
          StrikeReferenceLines={V_StrikeReferenceLines}                
          IndexStockListItem={IndexStockListItem}
          // ProductType={ProductType}
          // UnderlyingProductLast={V_UnderlyingProductLast}
          // UnderlyingProductClose={V_UnderlyingProductClose}
          // UnderlyingProductDiffString={V_UnderlyingProductDiffString}
          // RevisionDateTimeHKLast={V_RevisionDateTimeHKLast}

        />

      </div>

    )

}

// export default UnderlyingProduct2;