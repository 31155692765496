// OptionDetailsRow.js

import React from 'react';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DoorFrontIcon from '@mui/icons-material/DoorFront';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import TextField from '@mui/material/TextField';
import ButtonGroup from '@mui/material/ButtonGroup';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { green, pink } from '@mui/material/colors';
import SwipeUpTwoToneIcon from '@mui/icons-material/SwipeUpTwoTone';
import SwipeDownTwoToneIcon from '@mui/icons-material/SwipeDownTwoTone';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Chip from '@mui/material/Chip';
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';

import AuthenticationService from '../AuthenticationService.js';
import NonClosedTableRow from './NonClosedTableRow.js';
import BidAskDisplay from './BidAskDisplay.js';
import { LongUnderlyingProductValue, Number2Dollar, FormattedNumber, CheckAuthenticated, ButtonAppBar, StartEnd, StartEnd2, Rounding, LongFormattedValue, SetBidAskValue, Bid, BidDetails, Ask, AskDetails, GroupSummaryCostValueSum, LabelBidAsk, RedColor_Set, date2string, CalcIntrinsicValue, jdate2string4display } from './Common.js';
import { VapingRoomsRounded } from '@mui/icons-material';
import { lightBlue } from '@material-ui/core/colors';

export default function OptionDetailsRow(props){

  const { 
    OptionDetailsListItem, 
    Key, 
    CallerType, 
    OptionList, 
    UnderlyingProductLast, 
    OptionDetailsListItem_Callback, 
    BaseContract_Callback, 
    GroupNumberListItem, 
    GroupNumberListItem_Callback 
  } = props;

  const [costPrice, setCostPrice] = React.useState('');
  const [valuePrice, setValuePrice] = React.useState('');

  function RowSummary_IntrinsicValue_IntrinsicValueProfit_Existed(){

    switch(OptionDetailsListItem.Status){
  
      case 'Pending':
      case 'Opened':
  
        return(
          <React.Fragment>
            <br/><br/>
            <Grid item xs={12} lg={12}>
              {StartEnd2('內在值:', LongFormattedValue(OptionDetailsListItem.TotalIntrinsicValueAvg, 0, true), 7, 5)}
            </Grid>                       
            <Grid item xs={12} lg={12}>
              {StartEnd2('結算利潤:', LongFormattedValue(OptionDetailsListItem.TotalIntrinsicValueProfitAvg, 0, true), 7, 5)}
            </Grid>              
          </React.Fragment>
        )
  
      case 'Closed':        
      case 'Closed2Expired':
  
        return(
          <React.Fragment>
          </React.Fragment>
        )
  
      case 'Opened2Expired':
  
        return(
          <React.Fragment>
            <br/><br/>
            <Grid item xs={12} lg={12}>
              {StartEnd2('內在值:', LongFormattedValue(OptionDetailsListItem.TotalIntrinsicValueAvg, 0, true), 7, 5)}
            </Grid>                       
            <Grid item xs={12} lg={12}>
              {StartEnd2('結算利潤:', LongFormattedValue(OptionDetailsListItem.TotalIntrinsicValueProfitAvg, 0, true), 7, 5)}
            </Grid>              
          </React.Fragment>
        )
  
    }
  
  }

  function RowSummary_TotalValueAvg_TotalProfitAvg_Existed(){

    switch(OptionDetailsListItem.Status){
  
      case 'Pending':
      case 'Opened':
  
        var V_ValueContract = OptionDetailsListItem.RefValueContract;
        var V_ValuePrice = OptionDetailsListItem.RefValuePrice;
  
        var V_Label_Value = '(' + LabelBidAsk(V_ValueContract) + ': ' + V_ValuePrice + '; Contract: ' + V_ValueContract + ')';
        var V_SummaryTotalValueAvg = OptionDetailsListItem.TotalValueAvg;
  
        var V_Label_Profit = '((' + LongFormattedValue(OptionDetailsListItem.TotalValueAvg, 0, true) + ') + (' + LongFormattedValue(OptionDetailsListItem.TotalCostAvg, 0, true) + '))';
        var V_SummaryTotalProfitAvg = OptionDetailsListItem.TotalProfitAvg;
  
        return(
          <React.Fragment>
            <br/>
            <Grid item xs={12} lg={12}>
              {StartEnd2('平倉值:', LongFormattedValue(V_SummaryTotalValueAvg, 0, true), 8, 4)}
              {V_Label_Value}
            </Grid>                     
            <Grid item xs={12} lg={12}>
              {StartEnd2('平倉利潤:', LongFormattedValue(V_SummaryTotalProfitAvg, 0, true), 8, 4)}
              {V_Label_Profit}
            </Grid>              
          </React.Fragment>
        )
  
      case 'Closed':        
      case 'Closed2Expired':
  
        var V_ValueContract = OptionDetailsListItem.ValueContract;
        var V_ValuePrice = OptionDetailsListItem.ValuePrice;
  
        var V_Label_Value = '平倉值 (' + LabelBidAsk(V_ValueContract) + ': ' + V_ValuePrice + '; Contract: ' + V_ValueContract + '):';
        var V_SummaryTotalValueAvg = OptionDetailsListItem.TotalValueAvg;
  
        var V_Label_Profit = '平倉利潤 ((' + LongFormattedValue(OptionDetailsListItem.TotalValueAvg, 0, true) + ') + (' + LongFormattedValue(OptionDetailsListItem.TotalCostAvg, 0, true) + ')):';
        var V_SummaryTotalProfitAvg = OptionDetailsListItem.TotalProfitAvg;
  
        return(
          <React.Fragment>
            <br/><br/>
            <Grid item xs={12} lg={12}>
              {StartEnd2(V_Label_Value, LongFormattedValue(V_SummaryTotalValueAvg, 0, true), 8, 4)}
            </Grid>                     
            <Grid item xs={12} lg={12}>
              {StartEnd2(V_Label_Profit, LongFormattedValue(V_SummaryTotalProfitAvg, 0, true), 8, 4)}
            </Grid>              
          </React.Fragment>
        )
  
      case 'Opened2Expired':
  
        return(
          <React.Fragment>
          </React.Fragment>
        )
  
    }  
  
  }

  function RowSummary_TotalCostAvg_Existed(){

    switch(OptionDetailsListItem.Status){  
      case 'Pending':
  
        var V_CostContract = OptionDetailsListItem.RefCostContract;
        var V_CostPrice = OptionDetailsListItem.RefCostPrice; 
  
        break;
  
      case 'Opened':
      case 'Closed':
      case 'Opened2Expired':
      case 'Closed2Expired':
  
        var V_CostContract = OptionDetailsListItem.CostContract;
        var V_CostPrice = OptionDetailsListItem.CostPrice; 
  
        break;  
  
    }   
  
    var V_Label = '(' + LabelBidAsk(V_CostContract) + ': ' + V_CostPrice + '; Contract: ' + V_CostContract + ')';
    var V_SummaryTotalCostAvg = OptionDetailsListItem.TotalCostAvg;
  
    return(
      <React.Fragment>
        {StartEnd2('開倉值:', LongFormattedValue(V_SummaryTotalCostAvg, 0, true), 8, 4)}
        {V_Label}
      </React.Fragment>
    )  
  
  }

  function ProductCodeDisplay(){

    let _call_put = OptionDetailsListItem.CallPut;
    let _color;
    
    switch(_call_put)
    {
      
      case 'C':
        _color = 'success';
        break;
    
      case 'P':
        _color = 'error';
        break;
    
    }
    
    return(
      <React.Fragment>
        <Chip label={OptionDetailsListItem.ProductCode} color={_color}/>
      </React.Fragment>
    )
    
  }

  function NonClosedGreeks(){

    switch(OptionDetailsListItem.Status){
    
      case 'Pending':
      case 'Opened':
  
        return(
          <React.Fragment>
            <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
              <TableCell sx={{ paddingLeft: 1, paddingRight: 1 }} colSpan={3}>
                <Stack direction="row" spacing={1}>       
                  <Chip label={'Delta: ' + Rounding(OptionDetailsListItem.Delta, 2)} color="primary" variant="outlined" size="small" />
                  <Chip label={'Theta: ' + Rounding(OptionDetailsListItem.Theta, 2)} color="secondary" variant="outlined" size="small" />
                  <Chip label={'Vega: ' + Rounding(OptionDetailsListItem.Vega, 2)} color="warning" variant="outlined" size="small" />
                </Stack>
              </TableCell>
            </TableRow>
          </React.Fragment>
        )
  
      // case 'Closed':
      // case 'Closed2Expired':
  
      //   return(
      //     <React.Fragment>
      //     </React.Fragment>
      //   )

      default:

        return(
          <React.Fragment>
          </React.Fragment>
        )
  
    }
  
  }

  function onChange_CostPrice(e){
    setCostPrice(e.target.value);
  }

  function onChange_ValuePrice(e){
    setValuePrice(e.target.value);
  }

  function CostPriceReset(){
    setCostPrice('');
  }

  function ValuePriceReset(){
    setValuePrice('');
  }

  var V_Color;
  var V_DateTime;
  switch(OptionDetailsListItem.Status){
    case 'Pending':
      V_Color = 'primary';
      V_DateTime = date2string(OptionDetailsListItem.DateTimeHKPending, false, false, true);
      break;
    case 'Opened':
      V_Color = 'secondary';
      V_DateTime = date2string(OptionDetailsListItem.DateTimeHKOpened, false, false, true);
      break;
    case 'Closed':
      V_Color = 'warning';
      V_DateTime = date2string(OptionDetailsListItem.DateTimeHKClosed, false, false, true);
      break;
    case 'Opened2Expired':
      V_Color = 'info';
      V_DateTime = date2string(OptionDetailsListItem.DateTimeHKOpened2Expired, false, false, true);
      break;          
    case 'Closed2Expired':
      V_Color = 'success';
      V_DateTime = date2string(OptionDetailsListItem.DateTimeHKClosed2Expired, false, false, true);
      break;
  }

  return(

    <React.Fragment>

      <br/>

            <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
              <TableCell align="left" sx={{ paddingLeft: 1, paddingRight: 1 }} colSpan={3}>
                <Stack direction="row" spacing={1}>
                  <ProductCodeDisplay OptionDetailsListItem={OptionDetailsListItem}/>
                  <Chip label={OptionDetailsListItem.Status} color={V_Color} variant="outlined"/>
                  <Chip label={V_DateTime}/>
                </Stack>
              </TableCell>
            </TableRow>

            <NonClosedGreeks />

            <NonClosedTableRow
              OptionDetailsListItem={OptionDetailsListItem}
              Key={Key}
              CallerType={CallerType}
              OptionList={OptionList}
              OptionDetailsListItem_Callback={OptionDetailsListItem_Callback}
              CostPrice={costPrice}
              onChange_CostPrice={onChange_CostPrice}
              CostPriceReset={CostPriceReset}
              ValuePrice={valuePrice}
              onChange_ValuePrice={onChange_ValuePrice}
              ValuePriceReset={ValuePriceReset}
              BaseContract_Callback={BaseContract_Callback}
              GroupNumberListItem={GroupNumberListItem}
              GroupNumberListItem_Callback={GroupNumberListItem_Callback}
            />

            <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
              <TableCell align="left" sx={{ paddingLeft: 3, paddingRight: 3 }} colSpan={3}>
                <Grid container spacing={0}>
                  <Grid item xs={12} lg={12}>
                    <Chip label={OptionDetailsListItem.ID} variant="outlined" size='small'/>
                  </Grid>  
                  <br/><br/>                    
                  <Grid item xs={12} lg={12}>                        
                    <RowSummary_TotalCostAvg_Existed />
                    <RowSummary_TotalValueAvg_TotalProfitAvg_Existed />
                    <RowSummary_IntrinsicValue_IntrinsicValueProfit_Existed />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>

    </React.Fragment>

  )

}

// export default OptionDetailsRow;