// OptionTableRow.js

import { BrowserRouter, Route } from "react-router-dom";
import React from 'react';
import { useState, useEffect, createContext, useContext } from "react";
import ReactDOM from 'react-dom';
import { useRoutes, A, navigate } from "hookrouter";
import axios from 'axios';
import MaterialTable from "material-table";

import { InputLabel } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { sizing } from '@material-ui/system';
import Container from '@material-ui/core/Container';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FolderIcon from '@material-ui/icons/Folder';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { Alert, AlertTitle } from '@material-ui/lab';
import { DataGrid } from '@material-ui/data-grid';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import AuthenticationService from '../AuthenticationService.js';
import Options4Display from "./Options4Display.js";
import OptionTableListItemDetails from './OptionTableListItemDetails.js';
import { ChartDataDateTimeHKContext, IndexStockListItemContext } from '../Context.js';
import { LongUnderlyingProductValue, Number2Dollar, FormattedNumber, CheckAuthenticated, ButtonAppBar, StartEnd, StartEnd2, Rounding, date2string } from './Common.js';

export default function OptionTableRow(props) {
    
    const { 
      Key, 
      OptionTableListItem, 
      CallProductCodeSubmitAction, 
      PutProductCodeSubmitAction, 
      OptionList, 
      ToggleButton 
    } = props;
    
    const [open, setOpen] = React.useState(false);
  
    const IndexStockListItem = useContext(IndexStockListItemContext);
  
    function ValueDisplay(P_CallPut){

      var V_ToggleButtonValue;
      var V_ToggleButtonValueRevisionDateTime1;
      var V_ToggleButtonValueRevisionDateTime2;
  
      switch(ToggleButton){
  
        case 'OI':
  
          switch(P_CallPut){
            case 'Call':
              var V_CallOpenInterestDiff = LongUnderlyingProductValue("IndexOption", OptionTableListItem.CallOpenInterestDiff);
              if (OptionTableListItem.CallOpenInterestDiff > 0) V_CallOpenInterestDiff = '+' + V_CallOpenInterestDiff;
              V_ToggleButtonValue = LongUnderlyingProductValue("IndexOption", OptionTableListItem.CallOpenInterest) + ' (' + V_CallOpenInterestDiff + ')';
              V_ToggleButtonValueRevisionDateTime1 = date2string(OptionTableListItem.RevisionDateTimeHKCallOpenInterest, true, false, false);
              V_ToggleButtonValueRevisionDateTime2 = date2string(OptionTableListItem.RevisionDateTimeHKCallPreviousOpenInterest, true, false, false);
              break;
            case 'Put':
              var V_PutOpenInterestDiff = LongUnderlyingProductValue("IndexOption", OptionTableListItem.PutOpenInterestDiff);
              if (OptionTableListItem.PutOpenInterestDiff > 0) V_PutOpenInterestDiff = '+' + V_PutOpenInterestDiff;
              V_ToggleButtonValue = LongUnderlyingProductValue("IndexOption", OptionTableListItem.PutOpenInterest) + ' (' + V_PutOpenInterestDiff + ')';
              V_ToggleButtonValueRevisionDateTime1 = date2string(OptionTableListItem.RevisionDateTimeHKPutOpenInterest, true, false, false);
              V_ToggleButtonValueRevisionDateTime2 = date2string(OptionTableListItem.RevisionDateTimeHKPutPreviousOpenInterest, true, false, false);
              break;          
          }
  
          return(
            <React.Fragment>
              {V_ToggleButtonValue}<br/>{V_ToggleButtonValueRevisionDateTime1}<br/>{V_ToggleButtonValueRevisionDateTime2}
            </React.Fragment>
          );
  
        case 'Delta':
  
          switch(P_CallPut){
            case 'Call':
              return(
                <React.Fragment>
                  {Rounding(OptionTableListItem.CallDelta, 2)}
                </React.Fragment>
              );
            case 'Put':
              return(
                <React.Fragment>
                  {Rounding(OptionTableListItem.PutDelta, 2)}
                </React.Fragment>
              );
          }
  
        case 'Theta':
  
          switch(P_CallPut){
            case 'Call':
              return(
                <React.Fragment>
                  {Rounding(OptionTableListItem.CallTheta, 2)}
                </React.Fragment>
              );
            case 'Put':
              return(
                <React.Fragment>
                  {Rounding(OptionTableListItem.PutTheta, 2)}
                </React.Fragment>
              );
          }
  
        case 'Vega':
  
          switch(P_CallPut){
            case 'Call':
              return(
                <React.Fragment>
                  {Rounding(OptionTableListItem.CallVega, 2)}
                </React.Fragment>
              );
            case 'Put':
              return(
                <React.Fragment>
                  {Rounding(OptionTableListItem.PutVega, 2)}
                </React.Fragment>
              );
          }
  
        case 'VOL':
  
          switch(P_CallPut){
            case 'Call':
              return(
                <React.Fragment>
                  {Rounding(OptionTableListItem.CallVolume, 0)}
                </React.Fragment>
              );
            case 'Put':
              return(
                <React.Fragment>
                  {Rounding(OptionTableListItem.PutVolume, 0)}
                </React.Fragment>
              );
          }
  
      }
  
  }

    let _strike = OptionTableListItem.Strike;
    let _underlying_product_last = IndexStockListItem.Last;
    var _call_color;
    var _put_color;
    var _call_marked_color;
    var _put_marked_color;

    _call_color = _underlying_product_last > _strike ? "Silver" : "LightGrey";
    _put_color = _underlying_product_last < _strike ? "Silver" : "LightGrey";

    switch(ToggleButton){
      case 'OI':
        _call_marked_color = OptionTableListItem.CallMarked == true & OptionTableListItem.OpenInterestDiffMax == true ? '#f48fb1' : OptionTableListItem.CallMarked == true ? '#fce4ec' : _underlying_product_last > _strike ? "Silver" : "LightGrey";
        _put_marked_color = OptionTableListItem.PutMarked == true & OptionTableListItem.OpenInterestDiffMax == true ? '#f48fb1' : OptionTableListItem.PutMarked == true ? '#fce4ec' : _underlying_product_last < _strike ? "Silver" : "LightGrey";
        break;
      case 'Delta':
      case 'Theta':
      case 'Vega':
      case 'VOL':
        _call_marked_color = _underlying_product_last > _strike ? "Silver" : "LightGrey";
        _put_marked_color = _underlying_product_last < _strike ? "Silver" : "LightGrey";
        break;    
    }

    return (
  
      <React.Fragment>
      <TableRow key={Key} onClick={() => setOpen(!open)}>
        <TableCell align="right" sx={{ paddingLeft: 0, paddingRight: 0, backgroundColor: _call_color }}>{LongUnderlyingProductValue("IndexOption", OptionTableListItem.CallBid)}</TableCell>
        <TableCell align="right" sx={{ paddingLeft: 0, paddingRight: 0, backgroundColor: _call_color }}>{LongUnderlyingProductValue("IndexOption", OptionTableListItem.CallAsk)}</TableCell>
        <TableCell align="right" sx={{ paddingLeft: 0, paddingRight: 1, backgroundColor: _call_marked_color }}>{ValueDisplay('Call')}</TableCell>
        <TableCell align="center" sx={{ paddingLeft: 0, paddingRight: 0, backgroundColor: "lightblue" }}>{LongUnderlyingProductValue("IndexOption", OptionTableListItem.Strike)}</TableCell>
        <TableCell align="right" sx={{ paddingLeft: 0, paddingRight: 0, backgroundColor: _put_color }}>{LongUnderlyingProductValue("IndexOption", OptionTableListItem.PutBid)}</TableCell>
        <TableCell align="right" sx={{ paddingLeft: 0, paddingRight: 0, backgroundColor: _put_color }}>{LongUnderlyingProductValue("IndexOption", OptionTableListItem.PutAsk)}</TableCell>
        <TableCell align="right" sx={{ paddingLeft: 0, paddingRight: 1, backgroundColor: _put_marked_color }}>{ValueDisplay('Put')}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="center" sx={{ paddingLeft: 1, paddingRight: 1, paddingTop: 0, paddingBottom: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <OptionTableListItemDetails 
              CallProductCodeSubmitAction={CallProductCodeSubmitAction} 
              PutProductCodeSubmitAction={PutProductCodeSubmitAction} 
              ProductType={'IndexOption'} 
              Strike={OptionTableListItem.Strike} 
              OptionTableListItem={OptionTableListItem}
              // IndexStockListItem={IndexStockListItem}
              OptionList={OptionList}
            />
          </Collapse>
        </TableCell>
      </TableRow>
      </React.Fragment>
      
    )
  }

  // export default OptionTableRow;