// MaterialTableOptionDetailsList.js

import React from 'react';
import { useState, useEffect, createContext, useContext } from "react";
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DoorFrontIcon from '@mui/icons-material/DoorFront';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import TextField from '@mui/material/TextField';
import ButtonGroup from '@mui/material/ButtonGroup';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { green, pink } from '@mui/material/colors';
import SwipeUpTwoToneIcon from '@mui/icons-material/SwipeUpTwoTone';
import SwipeDownTwoToneIcon from '@mui/icons-material/SwipeDownTwoTone';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Chip from '@mui/material/Chip';

import AuthenticationService from '../AuthenticationService.js';
import GroupNumberListItem_DBSetTitle from './GroupNumberListItem_DBSetTitle.js';
import OptionDetailsListItem_DBSetBaseContract from './OptionDetailsListItem_DBSetBaseContract.js';
import OptionDetailsRow from './OptionDetailsRow.js';
import GroupNumberListItemDelete from './GroupNumberListItemDelete.js';
// OptionDetailsListItemAdd
import OptionDetailsListItemAdd from './OptionDetailsListItemAdd.js';
import OptionDetailsListItemDelete from './OptionDetailsListItemDelete.js';
import { Dollar2Point, LongUnderlyingProductValue, Number2Dollar, FormattedNumber, CheckAuthenticated, ButtonAppBar, StartEnd, StartEnd2, Rounding, LongFormattedValue, SetBidAskValue, Bid, Ask, GroupSummaryCostValueSum, CostSum } from './Common.js';

export default function MaterialTableOptionDetailsList(props){

  const { 
    GroupTitle_Callback123,
    GroupNumberListItem_Callback,
    GroupNumberListItemDelete_Callback,
    GroupNumberListItemDelete_PageUpdate,
    GroupNumberListItem,
    OptionList,
    CallerType,
    UnderlyingProductLast,
    OptionDetailsListItem_Callback
  } = props;

  const [TimeStatus, setTimeStatus] = useState('Current');
  const [GroupTitle, setGroupTitle] = useState('');

  function Caller(){
  
    switch(CallerType)
    {
  
      case 'Draft':
        return(
          <div>
          新組合
          </div>
        )
        break;
  
      case 'MyList':
        return(
          <div>
          組合：{GroupNumberListItem.Number}
          </div>
        )
        break;
  
      case 'Opened':
        break;
  
    }
  
  }

  function OptionDetailsList_Buttons(){
    
    switch(CallerType)
    {
      
      case 'Draft':
  
        return(
          <div>
            <Grid container spacing={1} align='center'>
              <Grid item xs={12} lg={12}>
                <Button variant="outlined" fullWidth="true" onClick={() => SubmitAction()}>加入我的清單</Button>
              </Grid>
            </Grid>
          </div>
        )
        break;
  
      case 'MyList':
  
        return(
          <div>
            <Grid container spacing={1} align='center'>
              <Grid item xs={6} lg={6}>
              </Grid>
              <Grid item xs={6} lg={6}>
                <Button variant="outlined" fullWidth="true" color="error" onClick={() => DeleteAction()}>刪除</Button>
              </Grid>
            </Grid>
          </div>
        )
        break;
  
    }
  
  } 

  function onChange_GroupTitle(P_GroupTitle){

    // setGroupTitle(P_GroupTitle);
    
    // GroupTitle_Callback123(P_GroupTitle);

    var V_GroupNumberListItem = GroupNumberListItem;

    V_GroupNumberListItem.GroupTitle = P_GroupTitle;
    V_GroupNumberListItem.Updated.GroupTitle = true;    

    // setGroupNumberListItem(V_GroupNumberListItem);
    GroupNumberListItem_Callback(V_GroupNumberListItem);

    // this.setState({GroupNumberListItem: V_GroupNumberListItem}, () => {
      
      // GroupNumberListItem_Callback(V_GroupNumberListItem);
    // });

    if (CallerType == 'MyList') GroupNumberListItem_DBSetTitle(V_GroupNumberListItem);    

  }

  function BaseContract_Callback(P_ProductCode, P_BaseContract){

    let _group_number_list_item = GroupNumberListItem;
    let _option_details_list = _group_number_list_item.OptionDetailsList;
    const _index = _option_details_list.findIndex(x => x.ProductCode == P_ProductCode);

    switch(_option_details_list[_index].Status){

      case 'Pending':

        _option_details_list[_index].RefCostContract = P_BaseContract;
        _option_details_list[_index].RefCostShare = P_BaseContract * 50;

        //

        _option_details_list[_index].RefValueContract = P_BaseContract * (-1);
        _option_details_list[_index].RefValueShare = P_BaseContract * 50 * (-1);

        if (_option_details_list[_index].RefCostContract > 0)
        {
          switch(_option_details_list[_index].CallPut)
          {
            case 'C':
              _option_details_list[_index].OptionType = 'LongCall'
            break;
            case 'P':
              _option_details_list[_index].OptionType = 'LongPut'
            break;
          }
        }
        else if (_option_details_list[_index].RefCostContract < 0)
        {
          switch(_option_details_list[_index].CallPut)
          {
            case 'C':
              _option_details_list[_index].OptionType = 'ShortCall'
            break;
            case 'P':
              _option_details_list[_index].OptionType = 'ShortPut'
            break;
          }
        }

      break;

      case 'Opened':

        _option_details_list[_index].RefValueContract = P_BaseContract;
        _option_details_list[_index].RefValueShare = P_BaseContract * 50;

      break;

    }

    SetBidAskValue(OptionList, _option_details_list[_index]);

    if (CallerType == 'MyList') OptionDetailsListItem_DBSetBaseContract(_option_details_list[_index]);

    GroupNumberListItem_Callback(_group_number_list_item);

}

function DeleteAction(){

    GroupNumberListItem.OptionDetailsList.forEach((_OptionDetailsListItem) => {
      OptionDetailsListItemDelete(_OptionDetailsListItem)
    });
  
    var v_http_post_data = {
      Credential: AuthenticationService.getCurrentUser(),
      OptionDetailsList: GroupNumberListItem.OptionDetailsList,
      TimeStatus: TimeStatus,
    }
  
    const url = sessionStorage.getItem('SiteURL') + '/api/OptionDetailsList/Put/';
  
    axios.post(url, v_http_post_data)
  
    .then(resp => {
      let _return_value = resp.data;
      GroupNumberListItemDelete(GroupNumberListItem, GroupNumberListItemDelete_Callback, GroupNumberListItemDelete_PageUpdate);
    });
  
  }

  function SubmitAction(e){    

    GroupNumberListItem.OptionDetailsList.forEach((_option_details_list_item) => {
      OptionDetailsListItemAdd(_option_details_list_item)
    });
  
    var v_http_post_data = {
      Credential: AuthenticationService.getCurrentUser(),
      GroupTitle: GroupNumberListItem.GroupTitle,
      OptionDetailsList: GroupNumberListItem.OptionDetailsList,
      TimeStatus: TimeStatus,
    }
  
    const url = sessionStorage.getItem('SiteURL') + '/api/GroupNumberListItem/Post/';
  
    axios.post(url, v_http_post_data)
  
    .then(resp => {

      let _return_value = resp.data;

      setGroupTitle('');

      GroupTitle_Callback123(GroupTitle);

      GroupNumberListItem.GroupTitle = '';
      GroupNumberListItem.Number = 0;
      GroupNumberListItem.OptionDetailsList = [];
      GroupNumberListItem.TotalCostAvg = 0;
      GroupNumberListItem.TotalValueAvg = 0;
      GroupNumberListItem.TotalProfitAvg = 0;
      GroupNumberListItem.TotalIntrinsicValueAvg = 0;
      GroupNumberListItem.TotalIntrinsicValueProfitAvg = 0;
      GroupNumberListItem.Active = true;
      GroupNumberListItem.AllExpired = false;

      GroupNumberListItem_Callback(GroupNumberListItem);

    });
  
  } 

  return (

    <div>

      <br/>

      <Box sx={{ paddingTop: 1, paddingBottom: 1, paddingLeft: 1, paddingRight: 1, backgroundColor: '#fff9c4' }}>

        <Grid container spacing={0} sx={{ paddingTop: 1, paddingBottom: 0, paddingLeft: 3, paddingRight: 3 }}>
          <Grid item xs={12} lg={12} sx={{ paddingBottom: 3 }}>
            {Caller()}
          </Grid>
          <Grid item xs={12} lg={12}>
            <TextField fullWidth value={GroupNumberListItem.GroupTitle} label="標題" focused id="GroupTitle" onChange={e => onChange_GroupTitle(e.target.value)}/><br/><br/>
          </Grid>
        </Grid>

      <TableContainer>
        <Table>
          <TableBody>

            {GroupNumberListItem.OptionDetailsList.map((M_OptionDetailsListItem) => (

              <OptionDetailsRow 
                Key={M_OptionDetailsListItem.ID} 
                OptionList={OptionList} 
                UnderlyingProductLast={UnderlyingProductLast}
                GroupNumberListItem={GroupNumberListItem}
                OptionDetailsListItem={M_OptionDetailsListItem} 
                BaseContract_Callback={BaseContract_Callback} 
                GroupNumberListItem_Callback={GroupNumberListItem_Callback}
                OptionDetailsListItem_Callback={OptionDetailsListItem_Callback} 
                CallerType={CallerType}
              />

            ))}
    
          </TableBody>

        </Table>

      </TableContainer>

        <br/>
        
        <Grid container spacing={0} sx={{ paddingTop: 3, paddingBottom: 0, paddingLeft: 3, paddingRight: 3 }}>
          <Grid item xs={7} lg={7} sx={{ paddingRight: 1 }}>
            {Caller()}
            <br/>
            {StartEnd2('開倉值:', LongFormattedValue(GroupNumberListItem.TotalCostAvg, 0, true), 5, 7)}
            <br/>
            {StartEnd2('平倉值:', LongFormattedValue(GroupNumberListItem.TotalValueAvg, 0, true), 5, 7)}
            {StartEnd2('平倉利潤:', LongFormattedValue(GroupNumberListItem.TotalProfitAvg, 0, true), 5, 7)}
            <br/>
            {StartEnd2('內在值:', LongFormattedValue(GroupNumberListItem.TotalIntrinsicValueAvg, 0, true), 5, 7)}
            {StartEnd2('結算利潤:', LongFormattedValue(GroupNumberListItem.TotalIntrinsicValueProfitAvg, 0, true), 5, 7)}
          </Grid>
          <Grid item xs={5} lg={5} sx={{ paddingLeft: 1, paddingRight: 1 }}>
            {OptionDetailsList_Buttons()}
          </Grid>
        </Grid>  

        <br/><br/>
    
        
        </Box>
        <br/>
    </div>

  )

}