// Test.js

import React from 'react';
import axios from 'axios';
import { useState, useEffect } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';

import AuthenticationService from './AuthenticationService.js';
import HomeProfit from './functions/HomeProfit.js';
import UnderlyingProductLineChart from './functions/UnderlyingProductLineChart.js';
import ButtonAppBar from "./functions/ButtonAppBar.js";
import StrikeReferenceLines_Get from './functions/StrikeReferenceLines_Get.js';
import UnderlyingProduct1 from './functions/UnderlyingProduct1.js';
import { LongUnderlyingProductValue, Number2Dollar, FormattedNumber, CheckAuthenticated, LongExpiry, ShortExpiry, Expiry_Get, date2string, UnderlyingProductValue_Get, getResponse, GroupSummaryCostValueSum, SetBidAskValue, GroupNumberList_Update } from './functions/Common.js';

function Test(){

  const [OptionList, setOptionList] = useState([]);

  useEffect(() => {

    var v_http_post_data = {
      Credential: AuthenticationService.getCurrentUser(),
      ProductType: 'IndexOption',
      UnderlyingProductCode: 'HK.HSI',
      TimeStatus: 'Current',
    }
    
    const url = sessionStorage.getItem('SiteURL') + '/api/OptionList/ByUnderlyingProductCode/';

    axios.post(url, v_http_post_data)

    .then(resp => {
          
      var V_OptionList = resp.data;
      setOptionList(V_OptionList);

    });

  }, []);

  if (OptionList.length > 0){
    return (
      <div>
        <ButtonAppBar />
        <UnderlyingProduct1 
          ProductType={'Index'} 
          UnderlyingProductCode={'HK.HSI'} 
          TimeStatus={'Current'}
          OptionList={OptionList}
        />
      </div>
    );
  }
  else{
    return (
      <div>
        <ButtonAppBar />
      </div>
    );
  }

}

export default Test;